<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style=" width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center  tw-items-center tw-self-end  tw-cursor-pointer"
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white; " />
      </div>
      <div v-if="section === 'main'"
        class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
        style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <label
          class="desc "
          v-if="description">{{ description }}</label>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-20">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="handleVerifyTransportCompany"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            {{this.title}}
          </v-btn>
        </div>
      </div>
      <div v-if="section === 'delete-post'"
           class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <label
            class="desc "
            v-if="description">{{ description }}</label>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-20">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="$emit('deletePost')"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Delete
          </v-btn>
        </div>
      </div>
      <div v-if="section === 'delete-booking'"
           class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <label
            class="desc "
            v-if="description">{{ description }}</label>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-20">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="$emit('deleteBooking')"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Delete
          </v-btn>
        </div>
      </div>
      <div v-if="section === 'pos'"
           class=" tw-flex card tw-p-5 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px">
        <icons :name="icon" :color="color" height="3rem" width="2.9rem" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-combobox :items="transportCompanies"  v-model="posRequest.selectedTransportCompany" item-text="companyName"
                      solo hide-details placeholder="Select Transport Company"
                      append-icon="mdi-chevron-down" @change="getAllTerminalsOfTransportCompany">

          </v-combobox>
        </div>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-combobox  solo hide-details placeholder="Select Terminal"
                       :items="transportCompanyTerminals" v-model="posRequest.selectedTerminal"
                       append-icon="mdi-chevron-down" item-text="terminalName">

          </v-combobox>
        </div>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-text-field  solo hide-details v-model="posRequest.numberOfPos" placeholder="Number of POS" type="Number">

          </v-text-field>
        </div>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-text-field  solo hide-details v-model="posRequest.unitPrice" placeholder="Unit Price" type="Number">

          </v-text-field>
        </div>
        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-5">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Cancel
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="addPosRequest"
              :style="{ background: color, color: 'white' }"
              :loading="posLoading">
            Submit Request
          </v-btn>
        </div>
      </div>
      <div v-if="section === 'create-voucher'" class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 484px" :style="{minWidth: $vuetify.breakpoint.mdAndUp ?'512px':'100%'}">
        <p class="header" :style="{color: color}">Create Voucher</p>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4">
          <label class="desc  text-capitalize" :style="{color:color}">
            Name
          </label>
          <v-text-field solo hide-details
                        placeholder="Title e.g (Detty December)"
                        class="tw-w-full" v-model="voucherDetail.name"></v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4">
          <label class="desc  text-capitalize" :style="{color:color}">
            Type
          </label>
          <v-select solo hide-details
                    placeholder=""
                    class="tw-w-full" v-model="voucherDetail.type"
                    :items="voucherTypes"></v-select>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Amount</label>
          <v-text-field solo hide-details
                        type="number" placeholder="50"
                        class="tw-w-full" v-model="voucherDetail.amount"
                        prepend-inner-icon="₦" :color="color">

          </v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Unit</label>
          <v-text-field solo hide-details
                        type="number" placeholder="100"
                        class="tw-w-full" v-model="voucherDetail.unit"
                        :color="color">

          </v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Short code (Optional)</label>
          <v-text-field solo hide-details
                        type="text" placeholder="NPOD & DON BABA"
                        class="tw-w-full" v-model="voucherDetail.schortCode"
                        :color="color">

          </v-text-field>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Deployment Channel</label>
          <v-select solo hide-details
                        type="text" placeholder="Select Channel (Web)"
                         :items="voucherDeploymentChannels"
                        class="tw-w-full" v-model="voucherDetail.deploymentChannel"
                        :color="color">

          </v-select>
        </div>
        <div class="tw-flex tw-items-start tw-w-full tw-flex-col tw-mt-4 tw-mb-8">
          <label class="desc text-capitalize" :style="{color:color}">Expiration Date</label>
          <v-menu v-model="voucherDateMenu" :close-on-content-click="true" :nudge-right="40"
                  transition="scale-transition" offset-y  class="tw-w-full">
            <template v-slot:activator="{ on }" class="tw-w-full">
              <ValidationProvider name="Date" rules="" v-slot="{ classes, errors }" class="tw-w-full">
                <div class="" :class="classes">
                  <v-text-field v-model="voucherDetail.expiryDate"  v-on="on"
                                placeholder="2022-09-9" solo hide-details readonly>
                    <template #prepend-inner>
                      <v-icon class="">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </template>

            <v-date-picker  locale="en-in" v-model="voucherDetail.expiryDate" no-title
                            @input="voucherMenu = false" :min="minDate">
            </v-date-picker>
          </v-menu>
        </div>
        <div class="tw-flex tw-justify-end tw-w-full tw-flex-col-reverse lg:tw-flex-row">
          <BaseButton :button-text="'Cancel'" :is-outlined="true" outlined="outlined"
                         class="lg:tw-mr-7 tw-mt-5 lg:tw-mt-0" :color="color"  @click="clearVoucherDetail"
                         :width="$vuetify.breakpoint.mdAndUp ? '174px' : '100%'"/>
          <BaseButton :button-text="'Create'" @click="sendVoucherDetail"  :color="color"
                         :width="$vuetify.breakpoint.mdAndUp ? '174px' : '100%'" :loading="createVoucherLoading"/>
        </div>
      </div>
      <div v-if="section === 'rectify-booking'"
           class="tw-flex card tw-p-5 tw-justify-start tw-items-start tw-flex-col tw-w-full log-detail"
           style="min-height: 384px">
        <p class="mid-title tw-py-5 tw-mb-0" :style="{color:color}">
          Log Details
        </p>
        <div class="tw-flex tw-w-full tw-flex-row desc">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="desc">Log type:</p>
          </div>
           <span class="mid-title">{{logData.logType}}</span>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row desc">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="desc">Require payment:</p>
          </div>
          <span class="mid-title">{{logData.requireCustomerPayment}}</span>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row desc">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="desc">Internal payment completed:</p>
          </div>
          <span class="mid-title">{{logData.customerPaymentCompleted}}</span>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row desc">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="desc">Payment reference: </p>
          </div>
          <span class="mid-title">{{logData.transactionReference}}</span>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row desc">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="desc">Amount Paid:  </p>
          </div>
          <span class="mid-title">&#8358;{{formatNumber(logData.amountPaid)}}</span>
        </div>

        <div class="tw-w-full"><v-divider class="tw-my-3" style="color: #004aad; border: 1px solid" ></v-divider></div>
        <div class="tw-flex tw-w-full tw-flex-row desc">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="">Completed step: </p>
          </div>
          <span class="mid-title">{{logData.completedStep}}</span>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row desc tw-py-4">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="">Failed step: </p>
          </div>
          <span class="mid-title">{{logData.failedStep}}</span>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row desc">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="desc">Message: </p>
          </div>
          <span class="mid-title">{{logData.message}}</span>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row desc tw-py-4">
          <div class="tw-flex tw-items-start tw-w-6/12">
            <p class="">Created At: </p>
          </div>
          <span class="mid-title">{{getDateTime(logData.createdAt)}}</span>
        </div>
        <div class="tw-w-full"><v-divider class="tw-my-3" style="color: #004aad; border: 1px solid" ></v-divider></div>

        <div class="tw-flex tw-w-full tw-py-3 tw-flex-col tw-items-start " v-if="logData.travellers && logData.travellers.length">
          <p class="desc"> Traveller Details</p>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-py-4" v-for="(traveller, index) in logData.travellers" :key="index">
            <div class="tw-flex tw-w-full tw-flex-row desc">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label >First name:</label>
              </div>
              {{traveller.firstName}}
            </div>
            <div class="tw-flex tw-w-full tw-flex-row desc">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label >Last name:</label>
              </div>
              {{traveller.lastName}}
            </div>
            <div class="tw-flex tw-w-full tw-flex-row desc">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label >Email:</label>
              </div>
              {{traveller.email}}
            </div>
            <div class="tw-flex tw-w-full tw-flex-row desc">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label >Phone Number:</label>
              </div>
              {{traveller.phoneNumber}}
            </div>
          </div>
          <div class="tw-w-full"><v-divider class="tw-my-3" style="color: #004aad; border: 1px solid" ></v-divider></div>
          <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-4 tw-justify-between">
            <p class="desc">Trip Details</p>

            <base-button button-text="Load Trip" :height="5" @click="getTripById(logData.tripId)"/>
          </div>

          <div class="tw-flex tw-w-full tw-flex-row desc">
            <div class="tw-flex tw-items-start tw-w-4/12">
              <label>Selected Seats: </label>
            </div>
            {{logData.selectedSeatNumbers}}
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start" v-if="tripData">
            <div class="tw-flex tw-flex-row tw-items-center tw-py-4">
              <img :src="tripData.transportCompanyLogo" alt="Company Log" class="company-logo">
              <h6 class="tw-ml-4">{{tripData.transportCompanyName}}</h6>
            </div>
            <div class="tw-flex tw-w-full tw-flex-row desc">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label>Departure : </label>
              </div>
              {{tripData.routeDepartureTerminalName}} &nbsp;{{tripData.routeDepartureCity + ", "+ tripData.routeDepartureState}}
            </div>
            <div class="tw-flex tw-w-full tw-flex-row desc">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label>Destination: </label>
              </div>
              {{tripData.routeDestinationTerminalName}}&nbsp;{{tripData.routeDestinationCity + ", "+ tripData.routeDestinationState}}
            </div>
            <div class="tw-flex tw-w-full tw-flex-row desc tw-py-5">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label>Date and Time: </label>
              </div>
              {{getDateTime(tripData.takeOffDate+tripData.takeOffTime)}}
            </div>
            <div class="tw-flex tw-w-full tw-flex-row desc">
              <div class="tw-flex tw-items-start tw-w-4/12">
                <label>Trip Amount: </label>
              </div>
               &#8358;{{formatNumber(tripData.tripAmount)}}
            </div>
            <div class="tw-flex tw-w-9/12 tw-py-5">
              <vehicle-seats :trip="tripData"/>
            </div>
            <p class="desc tw-py-4" style="color: red">Note: Kindly confirm that payment for this booking is successful</p>

            <base-button button-text="Submit" @click="rectifyFailedBooking" :loading="submitLoading"/>
          </div>

        </div>

      </div>

    </div>
  </v-dialog>
</template>

<script>
import check from "../../assets/check_one.svg";
import Icons from "@/components/reuseables/Icons";
import {
  AddPosRequest,
  createVoucher,
  getAllTransportCompanies,
  getTransportCompanyTerminals
} from "@/services/api/APIService";
import dayjs from "dayjs";
import {getTripByTripId, rectifyFailedBookingWithLogId} from "../../services/api/APIService";
import VehicleSeats from "./booking/VehicleSeats.vue";
import BaseButton from "./BaseButton.vue";
import {mapGetters} from "vuex";
export default {
  name: "Modal",
  components: {BaseButton, VehicleSeats, Icons },
  props: {
    dialog: Boolean,
    icon: {
      type: String,
      default: "check",
    },
    title: {
      type: String,
      default: "Title",
    },
    description: {
      type: String,
      default: "",
    },

    color: {
      type: [String],
      default: "#004AAD",
    },
    section:{
      type: String,
      default : "main"
    },
    loading:{
      type: Boolean,
      default: false
    },
    logData:null,
    voucherDetail:{},
  },
  data() {
    return {
      check,
      posRequest:{
        selectedTerminal : null,
        selectedTransportCompany:null,
      },
      transportCompanies :[],
      transportCompanyTerminals:[],
      posLoading: false,
      tripData:null,
      submitLoading: false,
      voucherDateMenu:null,
      voucherMenu : false,
      minDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 10),
      voucherTypes:["FIXED","PERCENTAGE"],
      voucherDeploymentChannels:["MOBILE","MOBILE_WEB","WEB"],
      createVoucherLoading:false,
    };
  },
  computed:{
    ...mapGetters("booking", ["seats"]),
    ...mapGetters("paddler",["logInPaddler"])
  },
  watch:{
    logData: {
      handler: function (val) {
        if (val){
          this.tripData = null
          this.getTripById(val.tripId)
        }
      },
      immediate:true,
      deep:true
    }
  },
  methods:{
    clearVoucherDetail(){
      this.$emit("voucherDetail", {});
      this.$emit("close");
    },
    async sendVoucherDetail(){
      if(this.voucherDetail.name
          && this.voucherDetail.unit
          && this.voucherDetail.amount
          && this.voucherDetail.type
          && this.voucherDetail.expiryDate) {
        this.createVoucherLoading = true;
        this.voucherDetail.voucherTag = "NOMADIAN"
        this.voucherDetail.status = "ACTIVE"
        this.voucherDetail.createdByEmail = this.logInPaddler.email;
        await createVoucher(this.voucherDetail).then(res =>{
          this.createVoucherLoading = false;
          if(res.data.status === 'successful'){
            this.$emit("getVouchers");
            this.$emit("close");
          }
        }).finally(()=> this.createVoucherLoading = false)
      }
      else {
        this.$displaySnackbar({message:'Incomplete Voucher Detail', success : false});
      }
    },
    async getTripById(tripId){
     if (tripId){
       let data = {}
       data.id = tripId
        await getTripByTripId(data).then(res => {
        this.tripData = res.data
        sessionStorage.setItem("userSelectedTrip", JSON.stringify(res.data))
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data,
          success: false
        })
      })}
    },
    async rectifyFailedBooking(){
      let totalSeatAmount = this.tripData.tripAmount * this.seats.length;
      if (totalSeatAmount !== this.logData.amountPaid ){
        this.$displaySnackbar({
          message: "The amount Paid is not equal to the total amount for the selected seat",
          success: false
        })
      }
      else {
        let data = {}
        this.submitLoading = true
        data.generalLogId = this.logData.logId
        data.selectedSeats = this.seats
        rectifyFailedBookingWithLogId(data).then(res => {
          this.$displaySnackbar({
            message: res.data.detail,
            success: true
          })
          this.submitLoading = false
        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
          this.submitLoading = false
        }).finally(() => this.submitLoading = false)
      }

    },
    handleVerifyTransportCompany(){
       this.$emit('verifyTransportCompany')
    },
    async getAllTransportCompanies(){
      await getAllTransportCompanies().then(res => {
        this.transportCompanies = res.data.filter(tc => tc.transportCompanyVerified === true)
      })
    },
    async getAllTerminalsOfTransportCompany(){
      this.posRequest.selectedTerminal = null
      if (Object.keys(this.posRequest.selectedTransportCompany).length) {
        await getTransportCompanyTerminals(this.posRequest.selectedTransportCompany.id).then(res => {
          this.transportCompanyTerminals = res.data
        })
      }
    },
    addPosRequest(){
      this.posLoading = true
      if (this.validRequest()) {
        let numberOfPosRequested = []
        for (let i = 1; i <= this.posRequest.numberOfPos; i++) {
          let pos = {}
          pos.unitPrice = this.posRequest.unitPrice
          numberOfPosRequested.push(pos)
        }
        this.posRequest.requestedPos = numberOfPosRequested
        this.posRequest.transportCompanyId = this.posRequest.selectedTransportCompany.id
        this.posRequest.terminalId = this.posRequest.selectedTerminal.id
        AddPosRequest(this.posRequest).then(() =>{
          this.posLoading = false
          this.$displaySnackbar({
            message: "Pos Request submitted successfully!",
            success: true
          })
          this.$emit('close')
        }).catch(err => {
              this.$displaySnackbar({
                message: err.response.data,
                success: false
              })
          this.posLoading = false
            }).finally(() => this.posLoading = false)
      }
      else {
        this.posLoading = false
      }
    },
    validRequest(){
      let valid = true
      if (this.posRequest.selectedTransportCompany === null
          || this.posRequest.selectedTerminal === null || !this.posRequest.numberOfPos || !this.posRequest.unitPrice){
        this.$displaySnackbar({
          message: "All fields are required",
          success: false
        })
        valid = false
      }
      return valid
    },
    getDateTime(date){
      return dayjs(date).format("lll")
    },
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
      else {
        return 0
      }
    },
  },
  async created() {
    await this.getAllTransportCompanies();
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 80% !important;
  @media screen and (max-width: 1600px) {
    width: 30% !important;
  }
  @media screen and (max-width: 1920px) {
    width: 30% !important;
  }
  @media screen and (max-width: 1300px){
    width: 45% !important;
  }

}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;
}
.title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px !important;
  margin-bottom: 0 !important;
  line-height: 44px;
  text-align: center;
  color: #004aad;
}

.mid-title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  margin-bottom: 0 !important;
  line-height: 24px;
  text-align: left;
}
.desc {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.second-btn{
  text-transform: none;
}
.first-btn{
  text-transform: none;
}
.company-logo{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
}
.log-detail{
  width: 100%;
  height: 60vh;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.log-detail::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 1px;
}
.log-detail::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 5px;
}
</style>
